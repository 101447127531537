@import "~@rentacenter/legacyracstrap/src/styles/vars";

.salesGoals {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -$SPACE_REGULAR;
  margin-bottom: -1.25rem;
  grid-gap: 10px 38px;
  
  .noResults {
    flex: 1;
  }
}

.custcard
{
  background-color: #fff;
  border-radius: 5px;
  padding: 1%;
  width: 23%;
  margin-bottom: 20px;
  box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important;
  position: relative;
}
