@import "~@rentacenter/legacyracstrap/src/styles/vars";

.noItems {
  height: pixelToRem(108);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  color: $NEUTRAL_03;
  font-size: $NORMAL-FONT-SIZE;
  font-weight: 600;

  svg {
    margin-right: $SPACE_SMALLER;
  }
}
