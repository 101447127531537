@import '~@rentacenter/legacyracstrap/src/styles/vars';

.storeGoalForm {
  position: relative;
  height: pixelToRem(85);
  width: 100%;

  .goalInputWrapper {
    margin-top: $SPACE_SMALL;

    .goalInput {
      width: pixelToRem(70);
      font-family: "Open Sans", sans-serif;
      margin-top: .25rem;
      border: .0625rem solid #b3c0cb;
      border-radius: .25rem;
      display: flex;
      background-color: #fff;
      padding: 2px 3px;
    }
  }

  .editButtonsGroup {
    position: absolute;
    bottom: $SPACE_SMALL;
    right: 0;

    .cancel {
      width: pixelToRem(69);
      font-size: 11px;
      font-family: "OpenSans-bold";
      background-color: #fff;
      border: .0625rem solid #0c7aab;
      color: #0c7aab;
      padding: 9px;
    }

    .save {
      width: pixelToRem(81);
      margin-left: $SPACE_MEDIUM;
      height: pixelToRem(39);
      font-size: 11px;
      font-family: "OpenSans-bold";
      background-color: #095b80;
      border: .0625rem solid #0c7aab;
      color: white;
      padding: 9px;
    }
  }
}
