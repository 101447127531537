@import "~@rentacenter/legacyracstrap/src/styles/vars";

.salesGoalCard {
  position: relative;
  max-width: pixelToRem(380);
  min-width: pixelToRem(290);
  flex: 1;
  flex-shrink: 0;


  .title {
    font-weight: 600;
    font-size: $BIG-FONT-SIZE;
  }

  .content {
    display: flex;
    margin-top: $SPACE_LARGE;
  }

  .titleContainer {
    display: flex;
  }

  .actionWrapper {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    min-width: 6rem;
  }
}

.salesGoalCard:last-child {
  margin-right: 0;
}

.test {
  display: flex;
  margin-top: 32px;
}

.cardheader {
  display: flex;
  justify-content: space-between;
}