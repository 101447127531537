@import "~@rentacenter/legacyracstrap/src/styles/vars";

.goalFormButtons {
  top: $SPACE_SMALL;
  right: $SPACE_SMALL;
  position: absolute;

  .cancel {
    font-size: 11px;
    font-family: 'OpenSans-bold';
    background-color: #fff;
    border: .0625rem solid #0c7aab;
    color: #0c7aab;
    padding: 6px 6px;
  }

  .save {
    font-size: 11px;
    font-family: 'OpenSans-bold';
    margin-left: $SPACE_MEDIUM;
    background-color: #095b80;
    color: #fff;
    padding: 6px 6px;

    &:hover{
      background-color: #3691b9;
    }
  }
}
