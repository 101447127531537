@import "~@rentacenter/legacyracstrap/src/styles/vars";

.loadingCard {
  flex: 1;
  margin-right: $SPACE_REGULAR;

  & > span:first-child {
    margin-bottom: $SPACE_LARGEST;
  }
}

.loadingCard:last-child {
  margin-right: 0;
}
