@import '~@rentacenter/legacyracstrap/src/styles/vars';

.metricType {
  font-weight: 600;
  font-size: $BIG-FONT-SIZE;
  margin-bottom: $SPACE_LARGE;
}

.collectionGoalCard {
  display: flex;

  .collectionGoalColumn {
    flex: 1;
    border-right: pixelToRem(1) solid $NEUTRAL_05;
    margin-right: $SPACE_MEDIUM;
    line-height: pixelToRem(16);

    .countName {
      margin: 0;
      margin-bottom: $SPACE_SMALL;
      font-size: $NORMAL-FONT-SIZE;
      font-weight: 600;
    }

    .count {
      margin-top: $SPACE_SMALL;
      font-size: $LARGE-FONT-SIZE;
      font-weight: 700;
    }
  }

  .collectionGoalColumn:last-child {
    border: none;
    margin-right: unset;
  }

  &>span:first-child {
    margin-bottom: $SPACE_LARGEST;
  }
}

@media (max-width: 1024px) {
  .collectionGoalCard {
    .collectionGoalColumn {
      margin-right: $SPACE_SMALLER;

      .countName {
        font-size: $SMALL-FONT-SIZE;
      }
      .count {
        font-size: $NORMAL-FONT-SIZE;
      }
    }
  }
}

.collectionGoalCardStyle {
  background-color: #fff;
  border-radius: 5px;
  padding: 1%;
  width: 23%;
  margin-bottom: 20px;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  position: relative;
  flex: 1 1;
  margin-top: 1.25rem;
  margin-right: 1.25rem;
  min-width: 18.125rem;
  max-width: 27.875rem;
}
