@import '~@rentacenter/legacyracstrap/src/styles/vars';

.wrapper {
  margin: -$SPACE_SMALL calc(#{$SPACE_LARGEST} / -2) 0;
  box-shadow: 0 pixelToRem(4) pixelToRem(7) 0 rgba(19, 37, 46, 0.08);

  .container {
    max-width: pixelToRem(500);
    margin: 0 calc(#{$SPACE_LARGEST} / 2);
    display: flex;

    label {
      width: 25%;
      display: flex;
      // margin-bottom: $SPACE_SMALL;
      align-items: center;
    }
  }
}

[collapsed-sidebar] .wrapper {
  margin: -$SPACE_SMALL calc(#{pixelToRem(80)} / -2) 0;
}

.selection {
  background-color: white;
  display: flex;
  padding: 10px;
  border: .0625rem solid #b3c0cb;
  border-radius: .25rem;
  position: relative;

  justify-content: space-between;

  div {
    margin-right: 11px;
  }

  ;

}

.check1 div:nth-child(2) {
  box-sizing: border-box;
  position: absolute;
  // top: 100%;
  // left: 0;
  width: 400px;
  background-color: #fff;
  border-bottom: .0625rem solid #3691b9;
  border-right: .0625rem solid #3691b9;
  border-bottom-right-radius: .25rem;
  border-bottom-left-radius: .25rem;
  // width: 100%;
  // display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  max-height: 12.75rem;
  overflow-y: auto;
  z-index: 9;
  overflow-x: clip;
  font-size: .875rem;
  font-family: "Open Sans", sans-serif;
  line-height: 1.3125rem;
  color: #252a31;
  font-weight: 400;
}

.check1 div div{
  border: 0px;
}
.check1 {
  width: 100%;
}

.check1 div>div>input {
  padding: 0px;
  height: 15px;
}

.check1 div:nth-child(2) {

  div {
    padding: .5rem .75rem;
    border: 0px;
    cursor: pointer;
    border-left: .0625rem solid #3691b9;
    position: unset !important;
    overflow-y: visible;
  }
}

.storeSelector {
  padding: 10px 0px;
}
.check1  p{
  color: red;
}