@import '~@rentacenter/legacyracstrap/src/styles/vars';

@import '../StoreGoalCard/StoreGoalCard.module';

.loadingCard {
  flex: 1;
  margin-top: $SPACE_REGULAR;
  margin-right: $SPACE_REGULAR;
  height: $cardHeight;
  min-width: $minCardWidth;
  max-width: $maxCardWidth;

  & > span:first-child {
    margin-bottom: $SPACE_LARGEST;
  }
}

.loadingCard:last-child {
  margin-right: 0;
}
