@import '~@rentacenter/legacyracstrap/src/styles/vars';

$RACPAD-HEADER-HEIGHT: pixelToRem(101);

.mainWrapper {
  display: flex;
  flex-direction: column;
  height: calc(100vh - #{$SPACE_REGULAR});

  &.containedMainWrapper {
    height: calc(100vh - #{$SPACE_REGULAR} - #{$RACPAD-HEADER-HEIGHT});
  }

  .tabs {
    margin-top: $SPACE_LARGE;
    min-height: pixelToRem(32);
  }

  .fullview {
    flex: 1;
    min-height: 0;
  }

  .permissionsError {
    margin-top: $SPACE_LARGE;
    color: $STATUS_YELLOW_06;
    svg {
      margin-right: $SPACE_SMALLER;
    }
  }
}
.check {
  background: #f4f4f4;
  padding: 8px 30px;
}
