@import '~@rentacenter/legacyracstrap/src/styles/vars';

.goals {
  .weekPicker {
    margin-top: pixelToRem(33);
    width: pixelToRem(200);
  }

  .section {
    margin: $SPACE_LARGER 0 $SPACE_LARGEST; 

    & > h3 {
      font-weight: 700;
      font-size: $LARGE-FONT-SIZE;
      margin: $SPACE_REGULAR 0;
    }
  }
}
.datePickerWrap {
  display: flex;
  align-items: flex-end;

  .datePicker {
    margin-right: $SPACE_MEDIUM;
    width: pixelToRem(180);
    height: 2.5rem ;
  }
}


.datesRow {
  display: flex;
  gap: pixelToRem(32);
  margin-top: 2rem;
}
.todayBtn{
  background-color: #fff !important;
  border: .0625rem solid #b3c0cb !important;
  color: #b3c0cb !important;
}

.custweekpicker input
{

  height: 1.25rem;
  font-size: .875rem;
  margin: 0 .125rem;
  color: currentColor;
}
.custweekpicker{
  font-family: "Open Sans", sans-serif;
    margin-top: .25rem;
    border: .0625rem solid #b3c0cb;
    border-radius: .25rem;
    display: -webkit-flex;
    display: flex;
    width: 100%;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    padding: 0px 11px;
}

.custweekpicker div div{
  background-color: red;
}